<template>
  <v-card
    color="element"
    :width="width"
    height="210"
    class="rounded-lg d-flex flex-column aligne-center text-center justify-center"
    :disabled="disabled"
  >
    <span class="categories-card-title">{{ title }}</span>
    <v-icon size="47">{{ icon }}</v-icon>
    <span v-if="footer" class="primary--text categories-card-footer">{{ footer }}</span>
  </v-card>
</template>

<script>
export default {
  name: 'CategoryCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 407,
    },
    icon: {
      type: String,
      required: true,
    },
    footer: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    next() {
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
.categories-card-title {
  font-size: 30px !important;
  font-weight: 500 !important;
}
.categories-card-footer {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
}
</style>
